.section-state {
    text-align: left;
    margin-top: 50px;

}

.stat-title {
    h2 {
        color: #58595B;
        font-family: Outfit-Regular, sans-serif;
        font-size: 34px;
        text-align: left;
        font-weight: normal;
    }
}

.stat-content {
    .statheading-wrapper {
        margin-top: 57px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .statdata-wrapper {
            max-width: 220px;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            margin: 25px 60px 25px 0;

            .statheading {
                display: flex;
                width: 100%;
                justify-content: space-between;


                h2 {
                    color: #fff;
                    font-family: Outfit-Regular, sans-serif;
                    font-size: 28px;
                    text-align: left;
                    margin-bottom: 0;
                }
            }

            .stat-bottom {
                margin-top: 50px;
                text-align: center;

                .number {
                    display: inline-flex;
                    align-items: baseline;

                    .common-num {
                        display: inline-block;
                        font-family: Outfit-Regular, sans-serif;
                        font-size: 24px;
                        color: #fff;
                        line-height: 1.1;
                    }

                    .num {
                        display: inline-block;
                        font-family: Outfit-Regular,
                            sans-serif;
                        font-size: 60px;
                        color: #BE9A2B;
                        line-height: 1.1;
                    }

                    .num-size {
                        display: inline-block;
                        font-family: Outfit-Regular,
                            sans-serif;
                        font-size: 31px;
                        color: #fff;
                        line-height: 1.1;
                    }
                }

                .statdesc {
                    p {
                        font-family: Outfit-light,
                            sans-serif;
                        font-size: 26px;
                        color: #58595B;
                        text-align: center;
                        margin: 0;
                        line-height: 1;
                    }
                }
            }

        }

    }
}

.fade-state {
    opacity: 0;
    animation: fade-state ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

.fade2-state {
    opacity: 0;
    animation: fade2-state ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade-state {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade2-state {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width:1200px) {
    .stat-content .statheading-wrapper {
        margin-top: 30px;

    }

    .stat-content .statheading-wrapper .statdata-wrapper .statheading {
        justify-content: unset;
    }

    .stat-content .statheading-wrapper .statdata-wrapper {
        flex-direction: row;
        align-items: center;
        max-width: 100%;
        margin: 0;
        padding: 25px 0;
        border-bottom: 1px solid #58595b7d;
    }

    .stat-content .statheading-wrapper .statdata-wrapper .stat-bottom {
        margin-top: 0;
        margin-left: 30px;
    }
}

@media screen and (max-width:992px) {
    .stat-content .statheading-wrapper .statdata-wrapper .statheading {
        h2 {
            font-size: 28px;
        }
    }

    .stat-content .statheading-wrapper .statdata-wrapper .stat-bottom .number .num {
        font-size: 50px;
    }

    .stat-content .statheading-wrapper .statdata-wrapper .stat-bottom .statdesc p {
        font-size: 24px;
        line-height: 1.3;
    }

    .stat-content .statheading-wrapper .statdata-wrapper .stat-bottom {
        margin-left: 10px;
    }

}

@media screen and (max-width:650px) {
    .stat-title h2 {
        font-size: 26px;
    }

    .stat-content .statheading-wrapper .statdata-wrapper .stat-bottom .number .num {
        color: #BE9A2B;
        font-size: 44px;
        margin: 0 3px;
    }

    .stat-content .statheading-wrapper .statdata-wrapper .statheading h2 {
        font-size: 24px;
    }

    .stat-content .statheading-wrapper .statdata-wrapper .stat-bottom .statdesc {
        margin-top: 10px;
    }

    .stat-content .statheading-wrapper .statdata-wrapper .stat-bottom .statdesc p {
        font-size: 20px;
    }
}