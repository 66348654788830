.section-product {
    margin-top: 30px;

    .product-heading {
        h2 {
            font-size: 34px;
            text-align: left;
            font-family: Outfit-Regular,
                sans-serif;
            font-style: normal;
            color: #fff;
            text-align: center;
        }
    }
}

.accordionGnni {
    width: 100%;
    height: 100%;
    overflow: auto;

    .accordion-item {
        background-color: transparent !important;

        .accordion-header {
            display: flex;
            align-items: center;

            .gnniIcon {
                width: 40px;
                height: 40px;
                display: block;
                object-fit: contain;
            }

            .accordion-button:not(.collapsed) {
                color: #2D2D2D;

                .acc-title {
                    color: #fff;
                }

                .acc-tag {
                    color: #BE9A2B;
                }



            }

            .accordion-button .collapsed {
                color: #2D2D2D;
                box-shadow: none;
                border-bottom: 0.5px solid #58595b7d;
            }

            .accordion-button {
                background: transparent !important;
                border-bottom: 0.5px solid #58595b7d;
                padding: 1rem 0;
                display: flex;
                align-items: baseline;

                .acc-title {
                    font-size: 30px;
                    color: #323232;
                    font-family: Outfit-Medium,
                        sans-serif;
                    font-style: normal;
                    display: inline-block;
                    margin-left: 20px;
                }

                &:focus {
                    border: none;
                    box-shadow: none;
                }

                &:after {
                    margin-top: 0;
                    background-image: url("../../../Assets/Icon/gt-top.svg");
                    background-size: contain;
                    transform: rotate(90deg);
                }

                &:not(.collapsed)::after {
                    margin-top: 10px;
                    background-image: url('../../../Assets/Icon/cross.svg');
                }
            }

        }

        .accordion-body {
            max-width: 788px;
            width: 100%;
            padding: 10px 0;

            p {
                text-align: left;
                font-family: Outfit-light, sans-serif;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0px;
                color: #FFFFFF;
                line-height: 1.2;

                // margin: 15px 0;
                .highlight-link {
                    color: #BE9A2B;
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }
    }

}

.fade {
    opacity: 0;
    animation: fade ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade2 {
    opacity: 0;
    animation: fade2 ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade2 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}