.ifunds{
    @media screen and (max-width: 650px) {
       margin-bottom: 100px;
    }

    .table-responsive{
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 21px;
            height: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #be9a2b;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #be9a2b;
        }
    }

    .wrapper-main-menu{
        overflow-x: auto;
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 21px;
            height: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #be9a2b;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #be9a2b;
        }
        .main-menu-tabs{
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 835px) {
                width: 810px;
                justify-content: start;
            }
            .tab{
                cursor: pointer;
                padding: 10px;
                width: 300px;
                img{
                    width: 40px;
                }
                @media screen and (max-width: 1200px) {
                    width: 162px;
                    img{
                        width: 35px;
                    }
                }
                p{
                    color: #2D2D2D;
                    //font-family: Outfit-Medium, sans-serif;
                    font-weight: 800;
                    font-size: 16px;
                    @media screen and (max-width: 1200px) {
                        font-size: 12px;
                    }
                }
            }
            .active{
                //background: #BE9A2B;
                //border-top-right-radius: 5px;
                //border-top-left-radius: 5px;
                p{
                    color: #ffffff;
                }
            }
        }
    }

    .main-menu-content{
        .files{
            right: 0;
            @media screen and (max-width: 480px) {
                top: 0px;
            }
            p{
                font-size: 12px;
            }
            .name_file_icon{
                cursor: pointer;
            }
        }
        @media screen and (max-width: 991px) {
            .cards{
                margin-top: 15px !important;
            }
        }
        h5{
            text-align: left;
            font-family: Outfit-regular, sans-serif;
            //font-weight: 800;
            font-size: 12px;
            color: #ffffff;
        }
        .title_img{
            @media screen and (max-width: 550px) {
                flex-direction: column;
                .small{
                    align-self: start !important;
                    margin-bottom: 20px !important;
                }
            }
            .logo{
               color: #BE9A2B;
                font-weight: 900;
                font-size: 24px;
            }
            img{
                width: 60px;
            }
            @media screen and (max-width: 720px) {
                margin-top: 45px !important;
            }
            @media screen and (max-width: 407px) {
                margin-top: 55px !important;
            }
            h3{
                font-size: 24px;
                font-weight: 700;
                @media screen and (max-width: 1023px) {
                    font-size: 18px;
                }
            }
        }
    }

    .wrapper-sub-menu{
        overflow-x: auto;
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 21px;
            height: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #be9a2b;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #be9a2b;
        }
        .main-sub-menu-tab{
            @media screen and (max-width: 900px) {
                width: 900px;
            }
            font-family: Outfit-Regular, sans-serif;
            display: flex;
            justify-content: space-between;
            border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784);
            border-top: 0.5px solid rgba(88, 89, 91, 0.4901960784);
            .sub-tab{
                cursor: pointer;
                padding: 10px;
                width: 200px;
                p{
                    color: #58595B;
                    font-weight: 600;
                    font-size: 14px;
                }
            }
            .active{
                p{
                    color: #ffffff;
                }
            }
        }
    }

    .sub-menu-content{
        .ReactSpeedometer{
            div{
                margin: auto;
                svg{
                    width: 400px;
                }
            }
        }
        .overview{
            .img{
                //margin: auto;
                //text-align: center;
                img{
                    width: 440px;
                    @media screen and (max-width: 500px) {
                        width: 100% !important;
                    }
                }
            }
        }
        .performance{
            table > thead > tr{
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
            table > tbody > tr{
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
            .line-chart{
                height: 200px;
                border-bottom: 0.5px solid rgb(255, 255, 255);
            }
            .axis{
                margin-top: 5px;
                display: flex;
                color: #ffffff;
                justify-content: space-between;
            }
        }

        .asset_allocation{
            .main-wrapper{
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 1307px) {
                    justify-content: start !important;
                }
                @media screen and (max-width: 1307px) {
                    justify-content: space-between !important;
                }
                flex-wrap: wrap;
                .wrapper-1{
                    width: 300px;
                    @media screen and (max-width: 1307px) {
                        width: 400px !important;
                    }
                    @media screen and (max-width: 773px) {
                        width: 100% !important;
                    }
                }
                .wrapper-2{
                    @media screen and (max-width: 1307px) {
                        margin-left: 100px;
                    }
                    @media screen and (max-width: 900px) {
                        margin-left: 20px;
                    }
                    @media screen and (max-width: 773px) {
                        margin-left: unset !important;
                        margin-top: 30px;
                    }
                    @media screen and (max-width: 558px) {
                        margin: auto !important;
                        margin-top: 30px !important;
                        p{
                            text-align: center !important;
                        }
                    }
                }
                .wrapper-3{
                    @media screen and (max-width: 773px) {
                        margin-top: 30px;
                    }
                    @media screen and (max-width: 558px) {
                        margin: auto !important;
                        margin-top: 30px !important;
                        p{
                            text-align: center !important;
                        }
                    }
                }
                .chart{
                    padding: 80px 0;
                    width: 180px;
                    position: relative;
                    .center-text{
                        font-family: Outfit-Regular, sans-serif;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .radar-chart-wrapper{
                    display: flex;
                    @media screen and (max-width: 773px) {
                        flex-direction: column;
                    }
                    .radar-chart{
                        width: 350px !important;
                    }
                }
                .labels{
                    margin-left: 30px;
                    @media screen and (max-width: 558px) {
                        margin-left: unset !important;
                    }
                    .names, .values{
                        p{
                            font-size: 12px;
                            //margin-bottom: 5px !important;
                        }
                    }
                    .values{
                        .box-color{
                            width: 8px;
                            height: 8px;
                            margin-top: 5px !important;
                        }
                        .box-value{
                            margin-left: 5px !important;
                        }
                        p{
                            text-align: right;
                            margin-left: 30px !important;
                        }
                    }
                    .color{
                        margin-top: 5px;
                        p{
                            margin-bottom: 10px;
                            //margin-top: 10px;
                            margin-right: 5px;
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
            }
        }

        .fun-facts{
            table > thead > tr{
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
            table > tbody > tr{
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
            .small{
                font-size: 10px;
                display: none;
                color: #727171;
            }
        }
        .fees{
            table > thead > tr{
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
            table > tbody > tr{
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
        }

        .fund_documents{
            .years_months{
                background: black;
            }
            .cursor{
                cursor: pointer;
            }
            .doc_name{
                padding: 10px !important;
                border-top: 1px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
            .doc_name{
                border-top: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
            }
            .year{
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
                padding: 0 10px;
                p{
                    padding: 3px 0;
                    margin: 5px 0 !important;
                }
            }
            .month_report{
                padding: 0 10px;
                border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
                p{
                    padding: 3px 0;
                    margin: 5px 0 !important;
                }
            }
        }
    }
}