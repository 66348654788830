.section-footer {
    position: sticky;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    border-top: 0.5px solid #58595b7d;
    background-color: #000;
    z-index: 5000;
    height: 80px;
}

.hamburger1 {
    margin-left: 10px;
    margin-top: 8px;
}

.hamburger1 .line-res1 {
    width: 24px;
    height: 2px;
    background-color: #ecf0f1;
    display: block;
    margin: 7px auto;
    transition: all 0.3s ease-in-out;
}

.hamburger1:hover {
    cursor: pointer;
}

.line-res1:nth-child(1) {
    transform: rotate(90deg);
}

.line-res1:nth-child(2) {
    transform: translateY(-9px);
}

#hamburger1.is-active .line-res1:nth-child(1) {
    transform: rotate(315deg);
    transition: all .4s ease;
}

#hamburger1.is-active .line-res1:nth-child(2) {
    transform: rotate(54deg) translate(-7px, -5px);
    transition: all .4s ease;
}

#hamburger1.is-active .line-res1 {
    background-color: #BE9A2B;
}

.footer-content {
    display: flex;
    margin-left: 30px;
    margin-right: 0;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    position: relative;

    .footer-contact {
        display: flex;
        align-items: center;

        .contact-desc {
            display: flex;
            align-items: center;
            margin-left: 65px;
        }
    }

    .c-desc {
        margin: 0 45px;

        h3 {
            font-size: 16px;
            font-family: Outfit-light, sans-serif;
            font-style: normal;
            color: #fff;
            text-align: left;
            margin-bottom: 0;
        }

        a {
            text-decoration: none;
            display: block;
            font-size: 16px;
            font-family: Outfit-light,
                sans-serif;
            font-style: normal;
            color: #fff;
            text-align: left;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .cpy {
        color: #58595B;
        display: block;
        margin-left: 20px !important;
    }

    .footer-icon {
        text-decoration: none;
        color: #58595B;
        font-size: 18px;

        &:hover {
            color: #BE9A2B;
        }

        .fa-linkedin-in,
        .fa-twitter,
        .fa-instagram {
            margin-right: 8px;
        }
    }

}

.contact {
    display: flex;
    align-items: center;
    padding-right: 20px;
    position: relative;
    z-index: 50000;
    cursor: pointer;

    p {
        color: white;
    }

    img {
        margin-left: 20px;
        display: block;
    }

    &::after {
        content: "";
        position: absolute;
        width: 1px;
        border-right: 0.5px solid #58595b7d;
        right: 0;
        height: 70px;
        right: 0;
        top: -20px;
    }
}

.copyright {
    display: flex;
    align-items: center;
}

.footer-text-anim1 {
    position: absolute;
    left: 100px;
    color: #fff;
    opacity: 0;
    transition: all .5s ease .2s !important;
    z-index: -1;
    font-size: 17px;
    line-height: 21px;
    font-family: Outfit-light,
        sans-serif;
    font-style: normal;
    width: 40%;
    left: 50%;
    top: 50%;
    transform: translate(-80%, -50%);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

.footer-text-anim1.hide {
    opacity: 0;
    transform: translate(-80%, -50%);
    transition: all .5s ease .2s !important;
}

.footer-text-anim2.show {
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: all .5s ease .6s !important;
}

.footer-text-anim2 {
    position: absolute;
    z-index: -1;
    color: #fff;
    left: 29%;
    top: 50%;
    opacity: 1;
    transition: all .5s ease .6s !important;
    font-size: 17px;
    line-height: 21px;
    font-family: Outfit-light,
        sans-serif;
    font-style: normal;
    width: 40%;
    transform: translate(-50%, -50%);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

//Animation Part
.hidden {
    display: none !important;
}

.visual {
    opacity: 0;
}

#contactmenuid.exit {
    transition: all .3s ease;
    transition-delay: 250ms;
    opacity: 0;
    visibility: hidden;
    display: block;


    .c-desc {
        &:nth-child(1) {
            transform: translateX(-100px);
            transition: all .3s ease;
            opacity: 0;
            transition-delay: 250ms;
        }

        &:nth-child(2) {
            transform: translateX(-100px);
            transition: all .3s ease;
            opacity: 0;
            transition-delay: 150ms;


        }

        &:nth-child(3) {
            transform: translateX(-100px);
            transition: all .3s ease;
            opacity: 0;
            transition-delay: 100ms;


        }

    }
}

#contactmenuid.ismenuopen {
    transition: all .3s ease;
    transition-delay: 150ms;
    opacity: 1;
    visibility: visible;

    .c-desc {
        &:nth-child(1) {
            opacity: 1;
            transform: translateX(0);
            transition: all .3s ease;
            transition-delay: 50ms;


        }

        &:nth-child(2) {
            opacity: 1;
            transform: translateX(0);
            transition: all .3s ease;
            transition-delay: 110ms;


        }

        &:nth-child(3) {
            opacity: 1;
            transform: translateX(0);
            transition: all .3s ease;
            transition-delay: 130ms;


        }
    }
}

.effect1 {
    display: flex;
    align-items: center;
}

.fa-location-dot {
    color: #fff;
    font-size: 26px;
    display: inline-block;
    margin-right: 15px;
}

@media screen and (max-width:1600px) {
    .footer-content {
        margin-right: 0;
        margin-left: 0;
    }

    .footer-content .footer-contact .contact-desc {
        margin-left: 10px;
    }

    .copyright {
        margin-right: 100px;
    }

    .footer-text-anim2 {
        left: 31%;
    }
}

@media screen and (max-width:1300px) {
    .footer-content .c-desc {
        margin: 0 20px;
    }

    .footer-text-anim2 {
        left: 37%;
        width: 50%;
    }
}

@media screen and (max-width:1380px) {
    .copyright {
        margin-right: 0 !important;
    }
}

@media screen and (max-width:1200px) {
    .footer-content .c-desc {
        margin: 0 10px;
    }
}

@media screen and (max-width:1140px) {
    .footer-content {
        flex-direction: column;
        align-items: baseline;
        margin-top: 45px;

    }

    .footer-text-anim2 {
        width: 80%;
        top: 35%;
        left: 54%;
        transform: translate(-42%, 0);
        font-size: 16px;
    }

    .footer-text-anim1 {
        width: 80%;
        top: 37%;
        left: 50%;
        transform: translate(-46%, -100%);
        font-size: 16px;
    }

    .contact::after {
        height: 60px;
        top: -8px;
    }

    .footer-content .footer-contact {
        width: 100%;
        border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784);
        padding: 15px 0 12px 0;
    }

    #contactmenuid.exit {
        width: 100%;
        justify-content: space-around;
    }

    #contactmenuid.ismenuopen {
        width: 100%;
        justify-content: space-around;
    }

    .copyright {
        text-align: center;
        width: 100%;
        display: block;
        padding: 15px 0;
        margin: 0 !important;
    }
}

@media screen and (max-width:1024px) {
    .footer-content .c-desc h3 {
        font-size: 14px;
    }

    .effect1 {
        max-width: 180px;
        width: 100%;
    }

    .footer-content .c-desc a {
        font-size: 14px;
    }


}

@media screen and(max-width:733px) {
    .footer-content .c-desc h3 {
        font-size: 12px;
    }

    .footer-content .c-desc a {
        font-size: 12px;
    }

    #contactmenuid.ismenuopen {
        justify-content: space-between;
    }

    #contactmenuid.exit {
        justify-content: space-between;
    }
}

@media screen and (max-width:992px) {
    .copyright {
        margin-right: 80px;
    }

    .contact::after {
        height: 70px !important;
    }

    .footer-content .c-desc {
        margin: 0 10px !important;

        h3 {
            span {
                display: inline !important;
            }
        }
    }

    .footer-text-anim2 {
        width: 80%;
        top: 35%;
        left: 58%;
        transform: translate(-42%, 0);
        font-size: 16px;
    }
}

@media screen and (max-width:768px) {
    .footer-text-anim2 {
        width: 70%;
    }
}

@media screen and (max-width:650px) {
    .footer-content .footer-contact {
        display: none;
    }

    .footer-text-anim1 {
        display: none;
    }

    .footer-text-anim2 {
        display: none;
    }

    .footer-content {
        justify-content: center;
        padding: 5px 0 !important;
    }

    .footer-content {
        margin-top: 0 !important;
    }

    .section-footer {
        background: #171717 0% 0% no-repeat padding-box !important;
        height: 65px;
        position: fixed;
        bottom: 0;
    }
}