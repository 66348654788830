.people-content {
    margin: 50px 80px 0 0;

    .people-heading {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .horizontal-line {
            max-width: 40px;
            border-top: 1px solid #fff;
            height: 1px;
            width: 100%;
            margin-right: 20px;
        }

        .people-title {
            text-align: left;
            color: #fff;
            position: relative;
            font-family: Outfit-light, sans-serif;
            font-size: 31px;
            margin-bottom: 0;
            letter-spacing: 7.9px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .section-management {
        margin-top: 50px;
    }

    .header-wrapper {
        h4 {
            text-align: left;
            color: #fff;
            position: relative;
            font-family: Outfit-light,
                sans-serif;
            font-size: 24px;
            margin-bottom: 0;
            letter-spacing: 7.9px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .accordion {
        width: 100%;
        height: 100%;
        overflow: auto;

        .accordion-item {
            background-color: transparent !important;

            .accordion-button:not(.collapsed) {
                color: #58595b7d;

                .acc-title {
                    color: #fff;
                }

                .acc-tag {
                    color: #BE9A2B;
                }

            }

            .accordion-button .collapsed {
                color: #2D2D2D;
                box-shadow: none;
            }

            .accordion-button {

                background: transparent !important;
                border-bottom: 0.5px solid #58595b7d;
                padding: 1rem 0;
                display: flex;
                align-items: baseline;

                .acc-title {
                    font-size: 28px;
                    color: #2D2D2D;
                    font-family: Outfit-Regular,
                        sans-serif;
                    font-style: normal;
                    display: inline-block;
                }

                .acc-tag {
                    color: #58595b;
                    font-family: Outfit-light,
                        sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    display: inline-block;
                    margin-left: 25px;
                    text-transform: uppercase;
                    letter-spacing: 6.6px;
                }

                &:focus {
                    border: none;
                    box-shadow: none;
                }

                &:after {
                    margin-top: 0;
                    background-image: url("../../../Assets/Icon/gt-top.svg");
                    background-size: contain;
                    transform: rotate(90deg);
                }

                &:not(.collapsed)::after {
                    margin-top: 10px;
                    background-image: url('../../../Assets/Icon/cross.svg');
                }
            }

            .accordion-body {
                max-width: 788px;
                width: 100%;

                p {
                    text-align: left;
                    font-family: Outfit-light, sans-serif;
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    line-height: 1.4;
                    margin: 15px 0;

                }
            }
        }
    }
}

@media screen and (max-width:1600px) {
    .people-content .accordion .accordion-item .accordion-button .acc-tag {
        margin-left: 15px;
    }
}

@media screen and (max-width:1500px) {
    .people-content {
        margin-right: 30px;
    }

    .resacc-people {
        display: flex;
        align-items: baseline;
        flex-direction: column;
    }

    .people-content .accordion .accordion-item .accordion-button .acc-title {
        line-height: 1.4;
    }

    .people-content .accordion .accordion-item .accordion-button .acc-tag {
        margin-left: 0;
    }
}

@media screen and (max-width:1200px) {
    .people-content .accordion .accordion-item .accordion-button .acc-title {
        font-size: 26px;
    }

    .people-content .accordion .accordion-item .accordion-button .acc-tag {
        font-size: 16px;
        line-height: 1.2;
    }
}

@media screen and (max-width:992px) {
    .people-content .accordion .accordion-item .accordion-button .acc-title {
        font-size: 24px;
        line-height: 1.2;
    }

    .people-content .accordion .accordion-item .accordion-button .acc-tag {
        font-size: 15px;
    }
}

@media screen and (max-width:768px) {
    .people-content {
        margin-right: 15px;
    }

    .people-content .accordion .accordion-item .accordion-body {
        max-width: 95%;
        width: 100%;
    }
}

@media screen and (max-width:650px) {
    .people-content .people-heading {
        margin-bottom: 15px;
    }

    .people-content {
        margin-top: 40px;
    }

    .section-people {
        min-height: calc(100vh - 455px);
        height: auto;
    }

    .people-content .accordion .accordion-item .accordion-button .acc-title {
        font-size: 18px;
    }

    .people-content .accordion .accordion-item .accordion-button .acc-tag {
        font-size: 14px;
        margin-top: 8px;
    }
}