.section-solution {
    margin-top: 30px;

    .product-solution {
        max-width: 603px;
        width: 100%;

        h2 {
            font-size: 34px;
            text-align: left;
            font-family: Outfit-Regular, sans-serif;
            font-style: normal;
            color: #959595;
        }
    }

    .accordion {
        width: 100%;
        height: 100%;
        overflow: auto;

        .accordion-item {
            background-color: transparent !important;

            .accordion-button:not(.collapsed) {
                color: #2D2D2D;

                .acc-title {
                    color: #fff;
                }

                .acc-tag {
                    color: #BE9A2B;
                }



            }

            .accordion-button .collapsed {
                color: #2D2D2D;
                box-shadow: none;
                border-bottom: 0.5px solid #58595b7d;
            }

            .accordion-button {
                background: transparent !important;
                border-bottom: 0.5px solid #58595b7d;
                padding: 1rem 0;
                display: flex;
                align-items: baseline;

                .acc-title {
                    font-size: 30px;
                    color: #323232;
                    font-family: Outfit-Medium,
                        sans-serif;
                    font-style: normal;
                    display: inline-block;
                }

                .acc-tag {
                    color: #303032;
                    font-family: Outfit-light,
                        sans-serif;
                    font-size: 23px;
                    font-style: normal;
                    display: inline-block;
                    margin-left: 15px;
                }

                &:focus {
                    border: none;
                    box-shadow: none;
                }

                &:after {
                    margin-top: 0;
                    background-image: url("../../../Assets/Icon/gt-top.svg");
                    background-size: contain;
                    transform: rotate(90deg);
                }

                &:not(.collapsed)::after {
                    margin-top: 10px;
                    background-image: url('../../../Assets/Icon/cross.svg');
                }
            }

            // .accordion-collapse.collapse.show {
            //     .accordion-body {
            //         opacity: 1;
            //     }
            // }

            .accordion-body {
                max-width: 788px;
                width: 100%;
                padding: 10px 0;
                // opacity: 0;
                // transition: opacity .3s ease;

                p {
                    text-align: left;
                    font-family: Outfit-light, sans-serif;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    line-height: 1.2;
                    // margin: 15px 0;
                }
            }
        }
    }
}

.resacc-div {
    display: flex;
    align-items: baseline;
}

.fade {
    opacity: 0;
    animation: fade ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade2 {
    opacity: 0;
    animation: fade2 ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade2 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and(max-width:1300px) {
    .resacc-div {
        flex-direction: column;
    }

    .section-product .accordion .accordion-item .accordion-button .acc-tag {
        margin-left: 0;
    }
}

@media screen and (max-width:992px) {
    .section-product .accordion .accordion-item .accordion-button {
        align-items: center;
    }

    .section-product {
        max-width: 95%;
        width: 100%;
    }

    .section-product .product-heading h2 {
        font-size: 24px;
    }

    .section-product .accordion .accordion-item .accordion-button .acc-title {
        font-size: 26px;
        line-height: 1.4;
    }

    .section-product .accordion .accordion-item .accordion-button .acc-tag {
        font-size: 20px;
        line-height: 1.2;
    }
}

@media screen and (max-width:650px) {
    .section-product {
        max-width: 100%;
        width: 100%;
        margin-top: 0;
    }
}

@media screen and (max-width:450px) {
    .section-product .product-heading h2 {
        font-size: 20px;
    }

    .section-product .accordion .accordion-item .accordion-button .acc-title {
        font-size: 22px;
    }

    .section-product .accordion .accordion-item .accordion-button .acc-tag {
        font-size: 18px;
    }
}