.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent !important;
    color: #fff;

    &::after {
        opacity: 1;
    }
}

.nav-pills .nav-link {
    white-space: nowrap;
}

.nav {
    flex-wrap: nowrap !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in.active {
    display: block;
}

.fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
}

.nav-item {
    margin: 0 15px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.nav-link {
    color: #58595B;
    font-size: 20px;
    font-family: Outfit-light, sans-serif;
    font-style: normal;
    position: relative;
    padding: 5px 0;

    &::after {
        width: 100%;
        border-bottom: 0.5px solid #fff;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
    }

    &:hover {
        color: #fff;

        &::after {
            opacity: 1;
        }
    }
}

@media screen and (max-width:1058px) {
    .nav {
        overflow-x: scroll;
        max-width: 1000px;
        width: 100%;
    }

    .nav::-webkit-scrollbar {
        display: none;

    }
}