.nestedaccordion-main {

    .accordion,
    .accordion-1 {
        width: 100%;
        height: 100%;
        overflow: auto;

        .accordion-item {
            background-color: transparent !important;

            .main_acc-heading {
                .accordion-button:not(.collapsed) {
                    color: #fff;
                }

                .accordion-button .collapsed {
                    color: #2D2D2D;
                    box-shadow: none;
                }

                .accordion-button {
                    font-size: 30px;
                    color: #323232;
                    font-family: Outfit-Medium,
                        sans-serif;
                    font-style: normal;
                    margin-bottom: 0;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    background: transparent !important;
                    border-bottom: 0.5px solid #58595b7d;
                    padding: 1rem 0;
                    display: flex;
                    align-items: baseline;

                    &:focus {
                        border: none;
                        box-shadow: none;
                    }

                    &:after {
                        margin-top: 0;
                        background-image: url("../../Assets/Icon/gt-top.svg");
                        background-size: contain;
                        transform: rotate(90deg);
                    }

                    &:not(.collapsed)::after {
                        margin-top: 10px;
                        background-image: url('../../Assets/Icon/cross.svg');
                    }
                }
            }

            .main_acc-content {
                .accordion-1 {
                    .inner_acc-heading {
                        .accordion-button:not(.collapsed) {
                            color: #fff;
                            border-bottom: 0.5px solid #58595b7d;
                        }

                        .accordion-button {
                            color: #3e3e3e;
                            font-family: Outfit-light,
                                sans-serif;
                            font-size: 24px;
                            font-style: normal;
                            position: relative;
                            margin-bottom: 0;
                            font-weight: 500;
                            background: transparent !important;
                            border-bottom: 0.5px solid #58595b7d;
                            padding: 10px 0;
                            display: flex;
                            align-items: baseline;

                            &:focus {
                                border: none;
                                box-shadow: none;
                            }

                            &:after {
                                margin-top: 0;
                                background-image: url("../../Assets/Icon/gt-top.svg");
                                background-size: contain;
                                transform: rotate(90deg);
                            }

                            &:not(.collapsed)::after {
                                margin-top: 10px;
                                background-image: url('../../Assets/Icon/cross.svg');
                            }
                        }

                        .accordion-button .collapsed {
                            color: #2D2D2D;
                            border-bottom: 0.5px solid #58595b7d;
                            box-shadow: none;
                        }
                    }

                    .pdf-wrapper {
                        padding: 5px !important;
                    }
                }
            }
        }
    }

    .pdflink {
        text-decoration: none;
        display: block;
        text-align: left;
        margin-left: 15px;
        color: #BE9A2B;
        font-size: 18px;
        font-family: Outfit-light,
            sans-serif;
        font-style: normal;
        position: relative;
        padding: 5px 0;

        .fa-download {
            font-size: 16px;
            color: #BE9A2B;
            margin-right: 10px;
        }

        &:hover {
            color: #fff;

            .fa-download {
                color: #fff;
            }
        }
    }
}