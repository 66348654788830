.sectioncat-accordion {
    max-width: 350px;
    width: 100%;
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;

    .accordion {
        width: 100%;
        height: 650px;
        overflow-y: scroll;
    }

    .accordion-button:not(.collapsed) {
        color: #BE9A2B;
    }

    .accordion-button .collapsed {
        color: #2D2D2D;
        box-shadow: none;
    }

    .accordion-button:focus {
        border: none;
        box-shadow: none;
    }

    .accordion-button {
        font-size: 50px;
        color: #2D2D2D;
        font-family: Outfit-Regular,
            sans-serif;
        font-style: normal;
        background: transparent !important;
        border-bottom: 0.5px solid #58595b7d;
        padding: 1rem 0;
    }

    .accordion-button::after {
        margin-top: 10px;
        background-image: url('../../Assets/Icon/plus.svg');
    }

    .accordion-button:not(.collapsed)::after {
        margin-top: 10px;
        background-image: url('../../Assets/Icon/cross.svg');
    }
}

.res-sectioncat {
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #58595B;
    border-radius: 18px;
    position: absolute;
    padding: 0 15px;
    width: 100%;
    display: none;
    margin-top: 5px;
    z-index: 550;

    .accordion {
        height: 100%;
    }

    .accordion-button:not(.collapsed) {
        color: #BE9A2B;
    }

    .accordion-button .collapsed {
        color: #2D2D2D;
        box-shadow: none;
    }

    .accordion-button:focus {
        border: none;
        box-shadow: none;
    }

    .accordion-button {
        font-size: 50px;
        color: #2D2D2D;
        font-family: Outfit-Regular,
            sans-serif;
        font-style: normal;
        background: transparent !important;
        border-bottom: 0.5px solid #58595b7d;
        padding: 1rem 0;
    }

    .accordion-button::after {
        margin-top: 10px;
        background-image: url('../../Assets/Icon/plus.svg');
    }

    .accordion-button:not(.collapsed)::after {
        margin-top: 10px;
        background-image: url('../../Assets/Icon/cross.svg');
    }
}



.accordion::-webkit-scrollbar {
    display: none;
}

.investnow {
    margin-top: 20px;
    display: inline-block;

    a {
        display: inline-block;
        text-decoration: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 21px;
        padding: 6px 30px;
        color: #000000;
        text-align: left;
    }
}

.accordion-item {
    background-color: transparent !important;
}



.accordion-body {
    padding: 0;
}

.subtitle {
    color: #58595B;
    text-align: left;
    margin-bottom: 0;
}

.res-accordion {
    display: none;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #58595B;
    border-radius: 60px;
    width: 100%;
    padding: 5px;

    .accText-search {
        background: transparent;
        outline: none;
        border: none;
        width: 95%;
        color: #BBBBBB;
        font-family: Outfit-light,
            sans-serif;
        font-size: 18px;
        font-weight: normal;
        text-align: left;
        letter-spacing: 0px;

        &::placeholder {
            color: #BBBBBB;
            font-family: Outfit-light, sans-serif;
            font-size: 18px;
            font-weight: normal;
            text-align: left;
            letter-spacing: 0px;
        }
    }

    .icon-right {
        background: #BE9A2B 0% 0% no-repeat padding-box;
        padding: 12px 16px;
        border-radius: 50%;

        img {
            display: inline-block;
            transform: rotate(90deg);
            width: 15px;
            height: 15px;
        }
    }

}

@media screen and (max-width:992px) {
    .rightside {
        max-width: 300px;

    }

    .leftside-common {
        width: calc(100% - 300px);
    }

    .sectioncat-accordion {
        max-width: 300px;

        .accordion-button {
            font-size: 40px;
        }
    }


}

@media screen and (max-width:768px) {

    .sectioncat-accordion {
        max-width: 300px;

        .accordion-button {
            font-size: 35px;
        }
    }
}

@media screen and (max-width:650px) {
    .res-accordion {
        display: flex;
        margin-top: 30px;
    }

    .subtitle {
        font-size: 20px;
    }

    .res-sectioncat {
        display: block;

        .accordion-button {
            font-size: 25px;
        }
    }



    .section-home {
        // height: calc(100% - 160px);
        align-items: unset;
        min-height: calc(100vh - 110px);
        height: auto;
    }

    .sectioncat-accordion {
        display: none;
    }



    .rightside {
        order: 1;
        width: 100%;
        max-width: 100%;
        border-left: none;
    }

    .leftside-common {
        order: 2;
        height: 100%;
        width: 100%;
    }

}

@media screen and (max-width:450px) {
    .res-sectioncat {
        .accordion-button {
            font-size: 20px;
        }

        .subtitle {
            font-size: 18px;
        }
    }
}

.active-accor{
    color: #BE9A2B !important;
}