.subtitle {
    color: #58595B;
    text-decoration: none;
    text-align: left;
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;

    &:hover {
        color: #ffffff;

        &::before {
            opacity: 1;
        }
    }

    &::before {
        content: "";
        position: absolute;
        background-image: url("../../Assets//Icon//rightarrow1.svg");
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        left: 0;
        background-size: contain;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 0;
    }


}

.active {
    color: #ffffff;

    &::before {
        opacity: 1;
    }
}