.our_methodology{
  @media screen and (max-width: 650px) {
    margin-bottom: 100px;
  }
  h1{
    font-size: 70px;
    text-align: left;
    font-family: Outfit-Regular, sans-serif;
    font-style: normal;
    text-transform: capitalize;
    text-decoration: none;
    color: #FFFFFF;
    @media screen and (max-width: 1023px) {
      font-size: 40px;
    }
    @media screen and (max-width: 768px) {
      font-size: 35px;
    }
  }
  .our_methodology_head{
    cursor: pointer;
  }
  .our_methodology_desc{
    color: #959595;
  }
  #accordionExample{
    .accordion-item{
      background: transparent !important;
      border-bottom: 0.5px solid rgba(88, 89, 91, 0.4901960784) !important;
    }
    .accordion-button{
      padding: 15px 0;
      .resacc-div{
        display: flex;
        align-items: baseline;
        .acc-title{
          font-size: 30px;
          color: #323232;
          font-family: Outfit-Medium, sans-serif;
          font-style: normal;
          display: inline-block;
          margin-right: 15px;
          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
        }
        .acc-tag{
          color: #303032;
          font-family: Outfit-light, sans-serif;
          font-size: 16px;
          font-style: normal;
          display: inline-block;
          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
      background: transparent !important;
      outline: none !important;
      border: none !important;
      box-shadow: unset !important;
    }
    .accordion-body{
      text-align: left;
      font-family: Outfit-light, sans-serif;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #FFFFFF;
      line-height: 1.2;
      padding: 20px 0;
      .more{
        color: #BE9A2B !important;
      }
    }
  }
  .accordion .accordion-item .accordion-button:not(.collapsed) .acc-title {
    color: #fff !important;
  }
  .accordion .accordion-item .accordion-button:not(.collapsed) .acc-tag {
    color: #BE9A2B !important;
  }
  .accordion .accordion-item .accordion-button:after {
    margin-top: 0;
    background-image: url("../../Assets/Icon/gt-top.svg");
    background-size: contain;
    transform: rotate(90deg);
  }
  .accordion .accordion-item .accordion-button:not(.collapsed)::after {
    margin-top: 10px;
    background-image: url("../../Assets/Icon/cross.svg");
  }
  .accordion .accordion-item .accordion-body {
    max-width: 788px;
    width: 100%;
    padding: 10px 0;
  }
}