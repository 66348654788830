.signupcontent {
    // display: flex;
    margin-top: 15px;
    width: 100%;
    // justify-content: space-between;
}

.signup-head {
    h2 {
        font-size: 75px;
        font-family: Outfit-Regular,
            sans-serif;
        font-style: normal;
        color: #fff;
        text-align: center;
    }

}

.signup-img {
    margin-top: 62px;

    .signupimg {
        max-width: 81px;
        height: 90px;
        width: 100%;
    }
}

.signup-desc {
    max-width: 396px;
    width: 100%;
    margin-top: 20px;

    p {
        color: #fff;
        font-size: 24px;
        font-family: Outfit-light, sans-serif;
        font-style: normal;
        text-align: left;
    }
}

.signup-form {
    max-width: 350px;
    width: 100%;
    margin: 40px auto 0 auto;

    .form-wrapper {
        .error-text {
            text-align: left;
            margin-top: 5px;
            margin-left: 5px;
            color: #BE9A2B;
            font-family: Outfit-light,
                sans-serif;
            font-size: 18px;
            opacity: 0.9;

        }

        .first-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            flex-direction: column;

            .fullname-wrapper {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;

                .firstname-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;

                    .firstname {
                        background: transparent;
                        outline: none;
                        border: 0.5px solid #FFFFFF;
                        border-radius: 29px;
                        padding: 8px 25px;
                        color: #fff;
                        font-family: Outfit-light, sans-serif;
                        font-size: 18px;
                        box-shadow: 0 0 1px 0px white inset,
                            0 0 1px 0px white;
                        width: 100%;

                        &::placeholder {
                            color: #fff;
                            font-family: Outfit-light,
                                sans-serif;
                            font-size: 16px;
                            opacity: 0.5;
                        }
                    }
                }

                .lastname-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;

                    .lastname {
                        background: transparent;
                        outline: none;
                        border: 0.5px solid #FFFFFF;
                        border-radius: 29px;
                        padding: 8px 25px;
                        color: #fff;
                        font-family: Outfit-light, sans-serif;
                        font-size: 18px;
                        box-shadow: 0 0 1px 0px white inset,
                            0 0 1px 0px white;
                        width: 100%;


                        &::placeholder {
                            color: #fff;
                            font-family: Outfit-light,
                                sans-serif;
                            font-size: 16px;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        .second-row {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .email-wrapper {
                width: 100%;

                .emailadd {
                    background: transparent;
                    outline: none;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 29px;
                    padding: 8px 25px;
                    color: #fff;
                    font-family: Outfit-light, sans-serif;
                    font-size: 18px;
                    width: 100%;
                    box-shadow: 0 0 1px 0px white inset,
                        0 0 1px 0px white;

                    &::placeholder {
                        color: #fff;
                        font-family: Outfit-light,
                            sans-serif;
                        font-size: 16px;
                        opacity: 0.5;
                    }
                }
            }


        }

        .third-row {
            margin-bottom: 20px;

            .react-tel-input {

                .form-control {
                    width: 100%;
                    background: transparent;
                    outline: none;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 29px;
                    color: #fff;
                    font-family: Outfit-light,
                        sans-serif;
                    font-size: 18px;
                    width: 100%;
                    height: 45px;
                    padding-left: 55px;
                    box-shadow: 0 0 1px 0px white inset,
                        0 0 1px 0px white;
                }

                .flag-dropdown {
                    border-radius: 29px;

                    .selected-flag {
                        width: 42px;
                        border-radius: 29px;

                        &:hover,
                        &:focus {
                            border-radius: 29px;
                        }
                    }


                }

                .country-list {
                    .country {
                        text-align: left;
                    }
                }

                .active {
                    color: #7a7a7a !important;
                }
            }
        }

        .fourth-row {
            margin-bottom: 45px;

            .css-1s2u09g-control,
            .css-1pahdxg-control {
                background: transparent !important;
                outline: none;
                border: 0.5px solid #FFFFFF !important;
                border-radius: 29px !important;
                color: #fff;
                font-family: Outfit-light,
                    sans-serif;
                font-size: 18px;
                width: 100%;
                padding: 2px 10px;
                outline: none;
                // box-shadow: none;
                box-shadow: 0 0 1px 0px white inset,
                    0 0 1px 0px white;
            }

            .css-6j8wv5-Input,
            .css-qc6sy-singleValue {
                color: #fff;
                font-family: Outfit-light,
                    sans-serif;
                font-size: 18px;
                text-align: left;
            }

            .css-26l3qy-menu {
                text-align: left;
            }

            #react-select-3-listbox {
                text-align: left;
            }

            #react-select-5-listbox {
                text-align: left;
            }

            .css-14el2xx-placeholder {
                text-align: left;
            }
        }

        .submit-form {
            margin-top: 45px;
            position: relative;

            .submit-btn {
                text-align: left;
                display: block;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 21px;
                border: none;
                outline: none;
                padding: 5px 40px;
                color: #000;
                font-family: Outfit-light,
                    sans-serif;
                font-size: 18px;
                margin: 0 auto;
            }

            .spinner {
                position: absolute;
                top: -45px;
                left: 130px;
            }
        }
    }
}

@media screen and (max-width:1600px) {}

@media screen and (max-width:1350px) {}

@media screen and (max-width:1200px) {
    .signupcontent {
        flex-direction: column;
        align-items: flex-start;
    }


}

@media screen and (max-width:992px) {
    .signup-head h2 {
        font-size: 70px;
    }
}

@media screen and (max-width:768px) {
    .signup-head h2 {
        font-size: 60px;
    }

}

@media screen and (max-width:650px) {
    .section-signup {
        min-height: calc(100vh - 440px);
        height: auto;
        margin-top: 30px;
    }

    .signupcontent {
        display: unset;
        align-items: unset;
    }

    .signup-head h2 {
        font-size: 50px;
        line-height: 1.4;
    }

    .signup-desc {
        max-width: 80%;
        width: 100%;

        p {
            font-size: 16px;
            line-height: 1.4;
        }
    }

    .signup-form {
        margin: 30px auto !important;
        max-width: 80%;
    }
}

@media screen and (max-width:400px) {
    .signup-form {
        margin: 30px auto !important;
        max-width: 100%;
    }
}