.investment_goals{
    @media screen and (max-width: 650px) {
        margin-bottom: 100px;
    }
    h1{
        font-size: 70px;
        text-align: left;
        font-family: Outfit-Regular, sans-serif;
        font-style: normal;
        text-transform: capitalize;
        text-decoration: none;
        color: #FFFFFF;
        @media screen and (max-width: 1023px) {
            font-size: 40px;
        }
        @media screen and (max-width: 768px) {
            font-size: 35px;
        }
    }
    .methodology_head{
        font-size: 30px;
        text-align: left;
        cursor: pointer;
        font-family: Outfit-Regular, sans-serif;
        font-style: normal;
        text-transform: capitalize;
        color: #FFFFFF;
        p{
            border-bottom: 2px solid;
        }
        @media screen and (max-width: 1023px) {
            font-size: 24px;
        }
    }
    .investment_goals_card_wrapper{
        justify-content: space-between;
        @media screen and (max-width: 1250px) {
            justify-content: start !important;
        }
        .name{
            white-space: nowrap;
        }
        .readmore{
            display: inline-block;
            float: left;
            //margin-top: 10px;
            font-size: 16px;
            font-family: Outfit-light, sans-serif;
            padding: 4px 0;
            color: #BE9A2B;
            text-decoration: none;
            position: relative;
            border-bottom: 0.45px solid #BE9A2B;
        }
        .gticon{
            margin-left: 13px;
        }
        margin-top: 100px !important;
        @media screen and (max-width: 1500px) {
            margin-top: 20px !important;
        }
        @media screen and (max-width: 1250px) {
            margin-top: 0px !important;
        }
        .investment_goals_card{
            margin-top: 50px;
            //background: #404040;
            //padding: 15px;
            width: 300px;
            @media screen and (max-width: 620px) {
                width: 100% !important;
            }
            //width: 100%;
            h3{
                font-size: 24px;
                //font-weight: 800;
                color: #BE9A2B;
                font-family: Outfit-Regular, sans-serif;
            }
            p{
                width: 100%;
                line-height: 1.2em;
                height: 3.6em;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                font-family: Outfit-Regular, sans-serif;
                @media screen and (max-width: 620px) {
                    height: auto !important;
                }
            }
        }
    }
}