.tabs-wrapper {
    .nav-tabs {
        border-bottom: none !important;

        .nav-item {
            .nav-link {
                color: #58595B;
                font-size: 20px;
                font-family: Outfit-light,
                    sans-serif;
                font-style: normal;
                position: relative;
                padding: 5px 0;
                border: 0;
                background-color: transparent;
                background: transparent;

                &::after {
                    width: 100%;
                    border-bottom: 0.5px solid #fff;
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                }

                &:hover {
                    color: #fff;

                    &::after {
                        opacity: 1;
                    }
                }

                &.active {
                    color: #fff;

                    &::after {
                        opacity: 1;
                    }
                }



            }


        }
    }

    .tab-pane {
        margin-top: 25px;
    }
}

.fade {
    opacity: 0;
    animation: fade ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

.fade2 {
    opacity: 0;
    animation: fade2 ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade2 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width:650px) {
    .tab-content {
        min-height: 100%;
        height: auto;
    }
}