.about-content {
    margin: 50px 80px 0 0;

    .about-heading {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .horizontal-line {
            max-width: 40px;
            border-top: 1px solid #fff;
            height: 1px;
            width: 100%;
            margin-right: 20px;
        }

        .about-title {
            text-align: left;
            color: #fff;
            position: relative;
            font-family: Outfit-light, sans-serif;
            font-size: 31px;
            margin-bottom: 0;
            letter-spacing: 7.9px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .accordion {
        width: 100%;
        height: 100%;
        overflow: auto;

        .accordion-item {
            background-color: transparent !important;

            .accordion-button:not(.collapsed) {
                color: #fff;
                border-bottom: 0.5px solid #58595b7d;
            }

            .accordion-button .collapsed {
                color: #2D2D2D;
                box-shadow: none;
            }

            .accordion-button {
                font-size: 55px;
                color: #2D2D2D;
                font-family: Outfit-Regular,
                    sans-serif;
                font-style: normal;
                background: transparent !important;
                border-bottom: 0.5px solid #58595b7d;
                padding: 1rem 0;
                display: flex;
                align-items: baseline;

                &:focus {
                    border: none;
                    box-shadow: none;
                }

                &:after {
                    margin-top: 0;
                    background-image: url("../../../Assets/Icon/gt-top.svg");
                    background-size: contain;
                    transform: rotate(90deg);
                }

                &:not(.collapsed)::after {
                    margin-top: 10px;
                    background-image: url('../../../Assets/Icon/cross.svg');
                }
            }

            .accordion-body {
                max-width: 700px;
                width: 100%;

                p {
                    text-align: left;
                    font-family: Outfit-light, sans-serif;
                    font-size: 24px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    line-height: 1.2;
                    margin: 15px 0;

                }
            }
        }
    }
}

@media screen and (max-width:1400px) {
    .about-content {
        margin-right: 30px;
    }
}

@media screen and (max-width:992px) {
    .about-content .accordion .accordion-item .accordion-button {
        font-size: 40px;
        line-height: 1.2;
    }

    .about-content .accordion .accordion-item .accordion-body p {
        font-size: 22px;
        line-height: 1.2;
    }
}

@media screen and (max-width:768px) {
    .about-content .accordion .accordion-item .accordion-body {
        max-width: 98%;
        width: 100%;
    }

    .about-content .accordion .accordion-item .accordion-body p {
        font-size: 20px;
        line-height: 1.2;
    }
}

@media screen and (max-width:650px) {
    .about-content .accordion .accordion-item .accordion-body p {
        font-size: 18px;
        line-height: 1.2;
    }

    .section-about {
        min-height: calc(100vh - 465px);
        height: auto;
    }

    .about-content .accordion .accordion-item .accordion-button {
        font-size: 35px;
        line-height: 1.2;
    }

    .about-content {
        margin-right: 15px;
    }
}