.readmorecontent {
    display: flex;
    margin-top: 10px;
    width: 100%;
    align-items: center;
}

.readmore-head {
    margin-bottom: 50px;

    h2 {
        font-size: 75px;
        font-family: Outfit-Regular,
            sans-serif;
        font-style: normal;
        color: #fff;
        text-align: center;
    }

}

.readmore-img {
    margin-top: 30px;

    .readmoreimg {
        max-width: 81px;
        height: 90px;
        width: 100%;
    }
}

.readmore-desc {
    max-width: 396px;
    width: 100%;
    margin-top: 20px;

    p {
        color: #fff;
        font-size: 24px;
        font-family: Outfit-light, sans-serif;
        font-style: normal;
        text-align: left;
    }
}

.readmore-form {
    margin-left: 250px;
    max-width: 350px;
    width: 100%;

    .error-text {
        text-align: left;
        margin-top: 5px;
        margin-left: 5px;
        color: #BE9A2B;
        font-family: Outfit-light,
            sans-serif;
        font-size: 18px;
        opacity: 0.9;

    }

    .fullname-wrapper {
        width: 100%;
        margin-bottom: 35px;

        .fullname {
            background: transparent;
            outline: none;
            border: 0.5px solid #FFFFFF;
            border-radius: 29px;
            padding: 8px 25px;
            color: #fff;
            font-family: Outfit-light, sans-serif;
            font-size: 18px;
            width: 100%;
            box-shadow: 0 0 1px 0px white inset,
                0 0 1px 0px white;

            &::placeholder {
                color: #fff;
                font-family: Outfit-light,
                    sans-serif;
                font-size: 18px;
                opacity: 0.5;
            }
        }
    }

    .email-wrapper {
        width: 100%;
        margin-bottom: 30px;

        .emailadd {
            background: transparent;
            outline: none;
            border: 0.5px solid #FFFFFF;
            border-radius: 29px;
            padding: 8px 25px;
            color: #fff;
            font-family: Outfit-light, sans-serif;
            font-size: 18px;
            width: 100%;
            box-shadow: 0 0 1px 0px white inset,
                0 0 1px 0px white;

            &::placeholder {
                color: #fff;
                font-family: Outfit-light,
                    sans-serif;
                font-size: 18px;
                opacity: 0.5;
            }
        }
    }

    .country-wrapper {

        .css-1s2u09g-control,
        .css-1pahdxg-control {
            background: transparent !important;
            outline: none;
            border: 0.5px solid #FFFFFF !important;
            border-radius: 29px !important;
            color: #fff;
            font-family: Outfit-light,
                sans-serif;
            font-size: 18px;
            width: 100%;
            padding: 2px 10px;
            outline: none;
            box-shadow: none;
            box-shadow: 0 0 1px 0px white inset,
                0 0 1px 0px white;
        }

        .css-6j8wv5-Input,
        .css-qc6sy-singleValue {
            color: #fff;
            font-family: Outfit-light,
                sans-serif;
            font-size: 18px;
            text-align: left;
        }

        .css-26l3qy-menu {
            text-align: left;
        }

        #react-select-3-listbox {
            text-align: left;
        }

        #react-select-5-listbox {
            text-align: left;
        }

        .css-14el2xx-placeholder {
            text-align: left;
        }
    }


}

.download-pdf {
    width: calc(100% - 400px);

    .downloadmessage {
        display: flex;
        align-items: center;
        height: 100%;
        max-width: 400px;
        width: 100%;
        text-align: center;
        margin: 0 auto;

        h3 {
            font-family: Outfit-light,
                sans-serif;
            font-style: normal;
            font-size: 18px;
            line-height: 25px;
            color: #fff;

            a {
                color: #BE9A2B;
                text-decoration: none;
                display: inline-block;
                margin-right: 5px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}


.submit-form {
    margin-top: 40px;
    position: relative;

    .submit-btn {
        text-align: center;
        display: block;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 21px;
        border: none;
        outline: none;
        padding: 5px 40px;
        color: #000;
        font-family: Outfit-light,
            sans-serif;
        font-size: 18px;
        margin: 0 auto;
    }

    .spinner {
        position: absolute;
        top: -45px;
        left: 130px;
    }
}

@media screen and (max-width:1500px) {
    .readmore-form {
        margin-left: 100px;
        max-width: 400px;
    }
}

@media screen and (max-width:1300px) {
    .readmore-form {
        margin-left: 30px;
    }
}

@media screen and (max-width:1150px) {
    .download-pdf {
        margin-top: 50px;
        width: 100%;

        .downloadmessage {
            width: 100%;
            max-width: 80%;
            margin: 0;

            h3 {
                a {
                    margin: 0 5px;
                }
            }
        }
    }

    .readmorecontent {
        flex-direction: column;
    }

    .readmore-form {
        margin-left: 0;
        max-width: 400px;
        width: 100%;
        margin-bottom: 110px;
        margin-top: 50px;
    }
}

@media screen and (max-width:992px) {
    .readmore-form {
        margin-bottom: 100px;
    }

    .download-pdf {
        margin-top: 40px;
        width: 100%;
        height: calc(100vh - 685px);

        .downloadmessage {
            width: 100%;
            max-width: 100%;
            margin: 0;

            h3 {
                a {
                    margin: 0 5px;
                }
            }
        }
    }
}

@media screen and (max-width:576px) {
    .readmore-form {
        margin-bottom: 40px;
    }

    .download-pdf {
        margin-top: 30px;
        width: 100%;
        height: calc(100vh - 670px);

        .downloadmessage {
            width: 100%;
            max-width: 90%;
            margin: 0;

            h3 {
                a {
                    margin: 0 3px;
                }
            }
        }
    }
}