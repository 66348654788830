.section-partner {
    margin-top: 50px;

}

.fade {
    opacity: 0;
    animation: fade ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade2 {
    opacity: 0;
    animation: fade2 ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
}

@keyframes fade2 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.partner-heading {
    max-width: 1000px;
    width: 100%;
    margin-bottom: 10px;

    h2 {
        color: #fff;
        font-family: Outfit-Regular,
            sans-serif;
        font-size: 36px;
        text-align: left;
        font-weight: normal;
    }
}

.partner-desc {
    margin-bottom: 80px;

    p {
        color: #58595B;
        font-family: Outfit-Regular,
            sans-serif;
        font-size: 25px;
        line-height: 1.2;
        text-align: left;
        font-weight: normal;
        margin-bottom: 0;
    }
}

.partnerheading-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 50px;
    flex-wrap: wrap;
}

.partner-bottom {
    max-width: 200px;
    width: 100%;
    margin: 15px 0;

    .number {
        display: inline-flex;
        align-items: baseline;
        margin-bottom: 10px;

        .common-num {
            display: inline-block;
            font-family: Outfit-Regular, sans-serif;
            font-size: 24px;
            color: #fff;
            line-height: 1.1;
        }

        .num {
            display: inline-block;
            font-family: Outfit-Regular,
                sans-serif;
            font-size: 60px;
            color: #BE9A2B;
            line-height: 1.1;
            margin: 0 4px;
        }

        .num-size {
            display: inline-block;
            font-family: Outfit-Regular,
                sans-serif;
            font-size: 31px;
            color: #fff;
            line-height: 1.1;
        }
    }
}

.partnerdata-heading {
    h2 {
        font-family: Outfit-light,
            sans-serif;
        font-size: 26px;
        color: #58595B;
        text-align: center;
        margin: 0;
        line-height: 1.2;
    }
}

@media screen and (max-width:1300px) {
    .partner-heading {
        max-width: 100%;
        width: 100%;

        h2 {
            font-size: 34px;
        }
    }

    .partner-desc {
        margin-bottom: 50px;

        p {
            font-size: 20px;
        }
    }

    .partner-bottom {
        margin-right: 20px;
    }

    .partnerheading-wrapper {
        justify-content: unset !important;
        margin-right: 0;
    }
}

@media screen and (max-width:1100px) {
    .partnerheading-wrapper {
        justify-content: space-around !important;
    }
}