.press-content {
    margin: 50px 80px 0 0;

    .press-heading {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .horizontal-line {
            max-width: 40px;
            border-top: 1px solid #fff;
            height: 1px;
            width: 100%;
            margin-right: 20px;
        }

        .press-title {
            text-align: left;
            color: #fff;
            position: relative;
            font-family: Outfit-light, sans-serif;
            font-size: 31px;
            margin-bottom: 0;
            letter-spacing: 7.9px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

}

.section-press {
    .press-content {
        .accordion {
            width: 100%;
            height: 100%;
            overflow: auto;

            .accordion-item {
                background-color: transparent !important;

                .accordion-button:not(.collapsed) {
                    color: #fff;
                }

                .accordion-button .collapsed {
                    color: #2D2D2D;
                    box-shadow: none;
                }

                .accordion-button {
                    font-size: 55px;
                    color: #2D2D2D;
                    font-family: Outfit-Regular,
                        sans-serif;
                    font-style: normal;
                    background: transparent !important;
                    border-bottom: 0.5px solid #58595b7d;
                    padding: 1rem 0;
                    display: flex;
                    align-items: baseline;

                    &:focus {
                        border: none;
                        box-shadow: none;
                    }

                    &:after {
                        margin-top: 0;
                        background-image: url("../../../Assets/Icon/gt-top.svg");
                        background-size: contain;
                        transform: rotate(90deg);
                    }

                    &:not(.collapsed)::after {
                        margin-top: 10px;
                        background-image: url('../../../Assets/Icon/cross.svg');
                    }
                }

                .accordion-body {
                    max-width: 690px;
                    width: 100%;

                    .bullet1 {
                        text-align: left;
                        font-family: Outfit-light,
                            sans-serif;
                        font-size: 34px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        color: #58595b;
                        line-height: 1.2;
                        margin: 15px 0;
                    }

                    p {
                        text-align: left;
                        font-family: Outfit-light, sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        line-height: 1.2;
                        margin: 15px 0;

                        .highlight-mail {
                            color: #BE9A2B;
                            display: inline-block;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:650px) {
    .section-press {
        min-height: calc(100vh - 455px);
        height: auto;
    }
}