.section-hero {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
}

$lm-bg-color: #333;
$lm-text-color: #999;
$lm-link-color: #e74c3c;
$lm-height: 3em;
$lm-anim-speed: 25s;



.horizontal-listitems {
    text-decoration: none;
    display: inline-block;
    font-family: Outfit-Regular,
        sans-serif;
    font-size: 34px;
    font-weight: normal;
    font-style: normal;
    width: 210px;
    color: white;

    &:hover {
        color: white;
    }
}

.scrolling-words-box {
    height: 7.3rem;
    margin: auto;
    overflow: hidden;
    position: relative;

    ul {
        margin: 0 0.625rem;
        padding: 0;
        transform: translateY(-83.3333333333%);
        animation: scrollDown 12s ease-in-out infinite forwards;

        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 7.3rem;
            list-style: none;
            margin-left: 20px;
        }
    }


}



.vertical-listitems {
    font-size: 101px;
    text-align: left;
    font-family: Outfit-Regular,
        sans-serif;
    font-style: normal;
    display: block;
    text-decoration: none;
    color: #FFFFFF;
    white-space: nowrap;

    &:hover {
        color: #BE9A2B;
        transition: all .3s ease;
    }


}

// Variables
$item-count: 6;

@keyframes scrollDown {
    @for $i from 1 through ($item-count - 1) {

        // #{($i * 20) - 10%},
        // #{$i * 20%} {
        //     transform: translateY((-100% / $item-count) * $i);
        // }

        10%,
        20% {
            transform: translateY(-67%);
        }

        30%,
        40% {
            transform: translateY(-51%);
        }

        50%,
        60% {
            transform: translateY(-35%);
        }

        70%,
        80% {
            transform: translateY(-17%);
        }

        90%,
        100% {
            transform: translateY(0%);
            // display: none;
        }
    }
}

.resHorizontal-text {
    margin-top: 25px;
    overflow: hidden;
    display: none;
    position: relative;
    width: 100%;
    height: 50px;

    .big-title {
        font-family: Outfit-Regular,
            sans-serif;
        font-size: 34px;
        font-weight: normal;
        font-style: normal;
        margin: 0;
        display: block;
        white-space: nowrap;

        &.front {
            z-index: 50;
            color: #ffffff;
        }
    }

    span {
        display: inline-block;
        font-family: Outfit-Regular,
            sans-serif;
        font-size: 34px;
        font-weight: normal;
        font-style: normal;
        width: 210px;
    }
}

.moving {
    animation: animLeft 18s linear infinite;
}

.clipping-container {
    position: relative;
    background-size: cover;
    background: transparent;
    z-index: 20;
    width: 200px;
    height: 50px;
    overflow: hidden;
}

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.centered {
    @include centerer;
}

.resfirst p {
    color: #ffffff;
    text-transform: uppercase;
    padding: 5px 0;
}

.c1 {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.c2 {
    display: none;
    background: #1A1A1A 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px;
    height: auto;
    transition: all .3s ease;
}

.resfirst {
    display: none;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #81818138;
}

@keyframes animLeft {
    0% {
        transform: translate(0%, -50%)
    }

    100% {
        transform: translate(-100%, -50%)
    }
}

.contenthero {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .head-title {
        margin-right: 66px;

        h3 {
            text-align: right;
            font: normal normal 200 65px/53px Outfit-extralight;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }
    }



}


$lm-bg-color: #333;
$lm-text-color: #999;
$lm-link-color: #e74c3c;
$lm-height: 1.5em;
$lm-anim-speed: 14s;

// Variables


// .vertical-list-anim {
//     background-color: transparent;
//     height: 100%;
//     margin-left: 80px;

//     .verticallist-main {
//         color: $lm-text-color;
//         height: $lm-height;
//         line-height: $lm-height;
//         font-size: 101px;
//         width: 700px;
//         white-space: nowrap;

//         .verticalwrapper {
//             position: relative;
//             overflow: hidden;
//             height: 165px;

//             .vertical-listitems {
//                 text-align: left;
//                 font-family: Outfit-Regular,
//                     sans-serif;
//                 font-style: normal;
//                 font-size: 101px;
//                 text-decoration: none;
//             }

//             &:hover a:link,
//             &:hover a:visited {
//                 animation-play-state: paused;
//             }

//             a:link,
//             a:visited {
//                 display: block;
//                 color: #fff;
//                 position: relative;
//                 opacity: 0;
//                 top: -7em;
//                 left: 0;
//                 text-decoration: none;
//                 transition: all .25s ease-in-out;
//                 animation-direction: alternate;
//                 animation: topToBottom 15s ease-in-out both infinite;

//                 //&:nth-child(3) {
//                 //    opacity: 0;
//                 //}


//             }

//             a:hover,
//             a:active {
//                 background-color: transparent;
//                 color: #fff;
//             }

//         }
//     }
// }





@keyframes animate {
    0% {
        transform: translate(50%, 0);
    }

    100% {
        transform: translate(-50%, 0);
    }
}

@media screen and (max-width: 1600px) {
    .section-hero {
        width: 100%;

        .contenthero {
            .learnMore {
                margin-left: 30px !important;
                height: 100px;

                .vertical-listitems {
                    font-size: 80px;
                    line-height: 1.5;
                }

            }

            .head-title {
                margin-right: 20px;

                h3 {
                    font-size: 50px;
                    line-height: 1.2;
                }
            }
        }
    }

    .leftside-common {
        position: relative;
    }

    .rightarrow-div {
        img {
            width: 55px;
            height: 60px;
        }
    }

    .learnMore {
        width: 500px;
    }
}

@media screen and (max-width:1550px) {
    .vertical-list-anim .verticallist-main {
        max-width: 600px;
        width: 100%;
    }

    .vertical-list-anim {
        margin-left: 20px;
    }

    .section-hero .contenthero .head-title {
        max-width: 250px;
        width: 100%;
    }

    .section-hero .contenthero .head-title h3 {
        font-size: 40px;
    }
}

@media screen and (max-width:1330px) {
    .contenthero {
        flex-direction: column;
    }

    .c1 {
        flex-direction: column;
    }

    .scrolling-words-box ul li {
        justify-content: center;
    }

    .vertical-list-anim {
        margin-left: 0;
        margin-top: 5px;
    }

    .rightarrow-div {
        transform: rotate(90deg);
    }

    .section-hero .contenthero .head-title {
        max-width: 550px;
        width: 100%;
    }

    .section-hero .contenthero .learnMore {
        margin-top: 35px;
        margin-left: 0 !important;
        width: 100%;

        p {
            width: 100%;
        }

        .vertical-listitems {
            display: block;
            text-align: center;
            width: 100%;
            margin: 0 auto;
        }
    }


    .section-hero .contenthero .head-title {
        margin-right: 0 !important;
        margin-bottom: 30px;

        h3 {
            font-size: 55px;
            text-align: center;
        }
    }

    .vertical-list-anim .verticallist-main .verticalwrapper .vertical-listitems {
        text-align: center;
    }
}

@media screen and (max-width:1200px) {
    .vertical-listitems {
        font-size: 90px;
    }

    .contenthero {
        flex-direction: column;
    }

    .rightarrow-div {
        transform: rotate(90deg);
    }

    .c1 {
        flex-direction: column;
    }



    .section-hero .contenthero .learnMore {
        margin-top: 35px;
        margin-left: 0 !important;
        width: 100%;

        p {
            width: 100%;
        }

        .vertical-listitems {
            display: block;
            text-align: center;
            width: 100%;
            margin: 0 auto;
        }
    }


    .section-hero .contenthero .head-title {
        margin-right: 0 !important;
        margin-bottom: 30px;

        h3 {
            font-size: 55px;
            text-align: center;
        }
    }
}

@media screen and (max-width:992px) {
    .vertical-listitems {
        font-size: 70px;
    }

    .section-hero .contenthero .slidingVertical {
        width: 100%;
        margin-left: 0 !important;
    }

    .vertical-list-anim .verticallist-main .verticalwrapper {
        height: 100%;
    }

    .vertical-list-anim {
        margin-top: 15px;
    }

    .vertical-list-anim .verticallist-main .verticalwrapper .vertical-listitems {
        font-size: 70px
    }

    .vertical-list-anim .verticallist-main {
        height: 1.4em;
        line-height: 1.4em;
        font-size: 70px;
    }

    .leftside-common {
        width: calc(100% - 300px);
    }
}

@media screen and (max-width:768px) {
    .vertical-listitems {
        font-size: 55px;
    }



    .vertical-list-anim .verticallist-main {
        height: 1.4em;
        line-height: 1.4em;
        font-size: 60px;
    }
}

@media screen and (max-width:650px) {
    .scrolling-words-box {
        display: none;
    }

    .resHorizontal-text .boxtext {
        display: flex;
        align-items: center;
    }

    .resHorizontal-text {
        display: flex;
        align-items: center;
    }

    .boxwhite {
        width: 210px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        height: 50px;
        background: #ffffff;
    }

    .section-hero {
        align-items: unset;
        position: relative;
        min-height: calc(100vh - 415px);
        height: auto;
    }

    .c2 {
        display: block;
        margin-bottom: 40px;
    }

    .vertical-list-anim {
        display: none;
    }

    .herocontent {
        flex-direction: column;
        justify-content: unset;
    }

    .leftside-common {
        width: 100%;
    }

    .section-hero .contenthero .head-title {
        margin-top: 70px;
        margin-bottom: 25px;

        h3 {
            font-size: 36px;
            line-height: 1.4;
        }
    }

    .resfirst {
        display: flex;

        p {
            font-size: 16px !important;
        }
    }
}