.overview-content {
    display: flex;
    // align-items: flex-start;
    margin-top: 85px;

    .overviewHead {
        max-width: 514px;
        width: 100%;

        h2 {
            color: #fff;
            font-size: 94px;
            font-family: Outfit-regular, sans-serif;
            font-style: normal;
            text-align: left;
            line-height: .9;
            margin-bottom: 0;
        }
    }

    .overviewDesc {
        max-width: 588px;
        width: 100%;
        margin-left: 100px;

        p {
            color: #fff;
            font-size: 24px;
            font-family: Outfit-light, sans-serif;
            font-style: normal;
            line-height: 1.2;
            text-align: left;
            margin-bottom: 0;
        }

        .readmore {
            display: inline-block;
            float: left;
            margin-top: 30px;
            font-size: 16px;
            font-family: Outfit-light, sans-serif;
            padding: 4px 0;
            color: #BE9A2B;
            text-decoration: none;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                border-bottom: 0.5px solid #BE9A2B;
            }

            .gticon {
                margin-left: 13px;
            }
        }
    }
}

.fadeUp {
    animation: fadeUp .5s ease 1 forwards;
}

.fadeUp2 {
    animation: fadeUp2 .5s ease 1 forwards;
}

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.fadeDown {
    animation: fadeDown .5s ease 1 forwards;
}

@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeUp2 {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}



@media screen and (max-width:1600px) {
    .overview-content {
        flex-direction: column;
    }

    .overview-content .overviewHead {
        max-width: 100%;
    }

    .overview-content .overviewDesc {
        margin-left: 0;
        margin-top: 30px;
        max-width: 100%;
    }
}

@media screen and (max-width:992px) {
    .overview-content .overviewHead {
        h2 {
            font-size: 80px;
            line-height: 1.1;
        }
    }

    .overview-content .overviewDesc {
        p {
            font-size: 18px;
        }
    }

    .overview-content {
        margin-top: 50px;
    }

    .nav {
        flex-wrap: nowrap;
    }
}

@media screen and (max-width:889px) {
    .nav {
        max-width: 561px;
        width: 100%;
        overflow-x: scroll;
    }

    .nav-item {
        white-space: nowrap;
    }

    .nav::-webkit-scrollbar {
        display: none;
    }

    .overview-content .overviewHead {
        h2 {
            font-size: 75px;
            line-height: 1;
        }
    }

    .overview-content .overviewDesc {
        p {
            font-size: 16px;
        }
    }

    .overview-content {
        margin-top: 40px;
    }
}

@media screen and (max-width:768px) {
    .overview-content .overviewHead {
        h2 {
            font-size: 65px;
            line-height: 1;
        }
    }
}

@media screen and (max-width:650px) {

    .res-overview {
        margin-bottom: 30px;
        min-height: calc(100vh - 485px);
        height: auto;
    }

    .tab-content {
        min-height: calc(100vh - 515px);
        height: auto;
    }

    .overview-content .overviewHead {
        max-width: 80%;
        width: 100%;

        h2 {
            font-size: 38px;
            line-height: 1.2;
        }
    }

    .overview-content {
        margin-top: 10px;
    }

    .overview-content .overviewDesc {
        max-width: 80%;
        width: 100%;

        p {
            line-height: 1.4;
        }
    }
}

@media screen and (max-width:480px) {
    .overview-content .overviewHead {
        max-width: 90%;
        width: 100%;
    }

    .overview-content .overviewDesc {
        max-width: 90%;
        width: 100%;
    }
}