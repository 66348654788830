.section-header {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 0.5px solid #58595b7d;
    z-index: 5000;
    background-color: #000;
}

.headerNav {
    display: flex;
    width: 100%;
    flex-direction: column;

}

.headernavheight {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.investnow {
    margin-top: 0 !important;
    margin-right: 80px !important;

    a {
        display: inline-block;
        text-decoration: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 21px;
        padding: 6px 30px;
        color: #000000;
        text-align: left;
    }
}

.headerNav1 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 28px 0;
}

.show-enter {
    opacity: 0;
    transform: scale(0.9);
}

.show-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
}

.show-exit {
    opacity: 1;
}

.show-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 200ms, transform 200ms;
}

.show1-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.show1-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 400ms, transform 400ms;
}

.show1-exit {
    opacity: 1;
    transform: translate(0);
}

.show1-exit-active {
    transform: translateX(-9999px);
    transition: transform 400ms;
}

.mobileRes-nav.width-auto {
    width: 100%;
    max-width: 95%;
    height: 100vh;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 6px 0px 13px #FFFFFF24;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .3s ease;
    padding: 39px 20px 20px 20px;


    .mobile-navitems {

        overflow-y: scroll;

        .mobile-hamicon {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            // margin-top: 50px;

            img {
                width: 25px;
                height: 25px;
            }
        }

        .mobile-list {
            margin-top: 30px;

            .item-list {
                text-align: left;
                padding: 20px 0;
                margin: 15px 0;
                border-bottom: 0.5px solid #58595B;

                a {
                    font-family: Outfit-light,
                        sans-serif;
                    font-size: 16px;
                    color: #FFFFFF;
                    text-decoration: none;
                    letter-spacing: 4.48px;
                    text-transform: uppercase;
                    display: block;
                }
            }
        }

        .speakwithus {
            //border: 0.5px solid #FFFFFF;
            border-radius: 28px;
            opacity: 1;
            padding: 6px 20px;
            display: inline-block;
            text-decoration: none;
            color: #FFFFFF;
            text-align: left;
            margin-top: 40px;
            float: left;
            
        }
    }

    .mobile-navitems::-webkit-scrollbar {
        display: none;
    }
}

.mobileRes-nav.width-0 {
    width: 0;
    max-width: 0;
    height: 100vh;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 6px 0px 13px #FFFFFF24;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .3s ease;



    .mobile-navitems {
        padding: 0;
        overflow-y: scroll;

        .mobile-hamicon {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            // margin-top: 50px;

            img {
                width: 25px;
                height: 25px;
            }
        }

        .mobile-list {
            margin-top: 30px;

            .item-list {
                text-align: left;
                padding: 20px 0;
                margin: 15px 0;
                border-bottom: 0.5px solid #58595B;

                a {
                    font-family: Outfit-light,
                        sans-serif;
                    font-size: 16px;
                    color: #FFFFFF;
                    text-decoration: none;
                    letter-spacing: 4.48px;
                    text-transform: uppercase;
                    display: block;
                }
            }
        }

        .speakwithus {
            border: 0.5px solid #FFFFFF;
            border-radius: 28px;
            opacity: 1;
            padding: 6px 38px;
            display: inline-block;
            text-decoration: none;
            color: #FFFFFF;
            text-align: left;
            margin-top: 80px;
            float: left;
        }
    }

    .mobile-navitems::-webkit-scrollbar {
        display: none;
    }
}

.navbar-items {
    display: flex;
    align-items: center;

    .speakwithus {
        //border: 0.5px solid #FFFFFF;
        border-radius: 28px;
        opacity: 1;
        //padding: 3px 25px;
        display: inline-block;
        text-decoration: none;
        color: #FFFFFF;
        text-align: left;
        margin-right: 40px;
    }

    .hamIcon {
        height: 23px;
        width: 40px;
        display: block;
        cursor: pointer;
    }
}

.list-items {}

.items-list {
    // display: none;
    // opacity: 0;
    // transition: opactiy .3s ease;
    justify-content: flex-end;

    .item-list {
        margin: 0 50px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .item-link {
            text-decoration: none;
            font-family: Outfit-light, sans-serif;
            font-size: 18px;
            color: #58595b;
            display: inline-block;
            position: relative;
            letter-spacing: 3.64px;
            padding: 7px 0;
            transition: all 0.3s ease;

            &::after {
                width: 100%;
                content: "";
                position: absolute;
                border-bottom: 0.5px solid #58595b;
                bottom: 0;
                left: 0;
                opacity: 0;
                transition: all 0.3s ease;
            }

            &:hover {
                color: #FFFFFF;

                &::after {
                    opacity: 1;
                }
            }
        }

        .item-link.active {
            color: #FFFFFF;

            &::after {
                opacity: 1;
            }
        }

    }
}

.navshow {
    // display: block;
    // opacity: 1;
}

@media screen and (max-width:992px) {
    .navbar-items .speakwithus {
        margin-right: 30px;
    }

    .items-list .item-list {
        margin: 0 30px;
    }
}

@media screen and (max-width:650px) {
    .navbar-items .speakwithus {
        display: none;
    }
}