.App {
  text-align: center;
  height: 100vh;
  position: relative;
  scroll-behavior: smooth;
}
.res-investnow {
  display: none;
}
::-webkit-scrollbar {
  width: 5px;
  border-radius: 21px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #be9a2b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc107;
}
.chat-bubble {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 6000;
  cursor: pointer;
}
.section-home {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 185px);
  height: auto;
}

.hrborder {
  border-bottom: 0.5px solid #58595b7d;
  width: 100%;
  display: inline-block;
}

.herocontent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: calc(100vh - 185px);
  height: auto;
  
}


.leftside-common {
  width: calc(100% - 370px);
}
.leftside-funds {
  width: 100%;
}

.rightside {
  max-width: 350px;
  width: 100%;
  border-left: 0.5px solid #58595b7d;
}
.res-cdesc {
  margin: 20px 0 29px 0;
}
.hamburger .line-res{
  width: 25px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  margin: 7px auto;
  transition: all 0.3s ease-in-out;
}
.hamburger:hover{
  cursor: pointer;
}
.line-res:nth-child(1){
  transform: rotate(90deg);
}
.line-res:nth-child(2){
  transform: translateY(-9px);
}
 #hamburger.is-active{
   transform: rotate(136deg);
   transition: all .4s ease;
 }
#hamburger.is-active .line-res{
  background-color: #BE9A2B;
}
.contact-expand{
  transition: all 50s ease-in !important;
}
.activeanim{
  transition: all 50s ease-in !important;
}
.res-cdesc a,
h3 {
  font-family: Outfit-light, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
  text-decoration: none;
  display: block;
}
.expandMenu-enter {
    opacity: 0;
    transform: scale(0.9);
}

.expandMenu-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
}

.expandMenu-exit {
    opacity: 1;
}

.expandMenu-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 200ms, transform 200ms;
}
@media screen and (max-width: 650px) {
  .chat-bubble {
    right: 35px;
  }
 .res-location-redirect{
   display: flex !important;
   align-items: flex-start;
   text-decoration: none;
 }
 
  .herocontent {
    height: unset;
  }
  .leftside-common {
    padding-bottom: 50px;
  }
  /*  .leftside {*/
  /*  padding-bottom: 50px;*/
  /*}*/
  .res-investnow {
    display: inline-block;
    margin: 30px 0;
  }
  .res-investnow a {
    display: inline-block;
    text-decoration: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 21px;
    padding: 6px 30px;
    color: #000000;
    text-align: left;
  }
  .accordion {
    height: auto !important;
  }
  .accordion-body{
    border-top: 1px solid #BE9A2B;
  }
  .res-cdesc:nth-child(2){
    margin-left: 30px;
  }
  .res-cdesc:nth-child(3){
    margin-left: 30px;
  }
  /* .footer-content{
    margin-top: 0 !important;
  } */
}

