body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  max-width: 1780px;
  width: 100%;
  margin: 0 auto;
}

/* commonfonts style */

@font-face {
  font-family: Outfit-Regular;
  src: url("./Fonts/Outfit/Outfit-Regular.ttf");
}
@font-face {
  font-family: Outfit-Medium;
  src: url("./Fonts/Outfit/Outfit-Medium.ttf");
}
@font-face {
  font-family: Outfit-light;
  src: url("./Fonts/Outfit/Outfit-Light.ttf");
}
@font-face {
  font-family: Outfit-extralight;
  src: url("./Fonts/Outfit/Outfit-ExtraLight.ttf");
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}
.fs-18{
  font-size: 18px;
}

.fs-20 {
  font-size: 22px;
}

.fs-22 {
  font-size: 22px;
}
.fs-28{
  font-size: 28px;
}
.fs-50{
  font-size: 50px;
}
.ff-regular {
  font-family: Outfit-Regular, sans-serif;
  font-style: normal;
}
.ff-light{
  font-family: Outfit-light,sans-serif;
  font-style: normal;
}
.ff-extralight{
  font-family: Outfit-extralight,sans-serif;
  font-style: normal;
}
.hrBottomLine {
  border-bottom: 0.5px solid #58595b;
  opacity: 0.4;
  display: inherit;
  width: 100%;
}

.light-gray{
  color: #a0a0a0;
}
